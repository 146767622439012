<template>
  <div class="detail-container">
    <!-- 面包屑导航-->
    <div class="crumbs clearfix">
      <router-link tag="div" class="item fl cur" to="/home">
        <img class="location" src="@/assets/images/icon_dw_h@2x.png" alt="">
        <span class="text1">首页 > </span>
      </router-link>
      <span class="text2">客户对账单</span>
    </div>
    <!--  条件-->
    <div class="form clearfix">
      <div class="date-range clearfix">
        <div class="date-input">
          <span>起始日期：</span>
          <div class="date-box">
            <el-date-picker
                v-model="date1"
                type="date"
                placeholder="输入开始日期" @change="jobSearch">
            </el-date-picker>
            <img src="@/assets/images/icon_rl@2x.png" alt="">
          </div>
        </div>
        <div class="date-group">
          <div class="item" :class="{active:dateRange==1}" @click="rangeHandle(1)">近一个月</div>
          <div class="item" :class="{active:dateRange==2}" @click="rangeHandle(2)">近三个月</div>
          <!--<div class="item" :class="{active:dateRange==3}" @click="rangeHandle(3)">近半年</div>-->
        </div>
        <div class="search" @click="searchHandle">查询</div>
      </div>
      <!--  导出-->
      <div class="output" @click="excel()">
        <img src="@/assets/images/icon_dc@2x.png" alt="">
        <span>导出</span>
      </div>
    </div>

    <!--  表格-->
    <div class="table-area">
      <table>
        <thead>
        <tr>
          <td>业务日期</td>
          <td>业务类型</td>
          <td  style="width: 300px !important;">摘要</td>
          <td>数量(吨)</td>
          <td>单价(元)</td>
          <td>发货款(元)</td>
          <td>收款(元)</td>
          <td>余额(元)</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in list" :key="index">
          <td>{{ item.dprocessdate }}</td>
          <td v-if="item.status==1" style="color:#31BB58 ">收款</td>
          <td v-if="item.status==0" style="color: #D70D18">耗用</td>
          <td>{{ item.zhyao }}</td>
          <td>{{ item.fhsl }}</td>
          <td>{{ item.nprice }}</td>
          <td>{{ item.dd }}</td>
          <td>{{ item.sk }}</td>
          <td>{{ item.yue }}</td>
        </tr>
        </tbody>
      </table>
      <el-empty v-if="list.length==0" description="暂无数据"></el-empty>
      <el-pagination v-if="list.length>0"
                     background
                     layout="prev, pager, next"
                     style="text-align: center"
                     @current-change="handleCurrentChange"
                     :page-size="pageSize"
                     :current-page="pageNum"
                     :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {
      date1: '',
      dateRange: 1,
      list: [],
      pageNum: 1,
      pageSize: 0,
      count: 0,

    };
  },
  components: {},
  props: {},
  mounted() {
    this.getBill();
  },
  methods: {
    rangeHandle(num) {
      this.date1 = ""
      this.dateRange = num
      this.pageNum = 1;
      this.getBill()
    },
    jobSearch() {
      this.dateRange = ""
    },
    searchHandle() {
      console.log(this.date1)
      console.log(this.DateDiffer(this.date1))
      if (this.DateDiffer(this.date1) > this.$store.state.selectDays) {
        this.$message.warning('只能查询' + this.$store.state.selectDays + '天之内的数据')
      }
      this.getBill();
    },
    //创建方法er
    DateDiffer(Date_end) {
      //date1结束时间
      let date1 = new Date(Date_end);
      //date2当前时间
      let date2 = new Date();
      date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
      date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
      const diff = date1.getTime() - date2.getTime(); //目标时间减去当前时间
      //计算当前时间与结束时间之间相差天数
      return Math.abs(diff / (24 * 60 * 60 * 1000));
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val
      this.getBill(1);
    },
    async getBill() {
      let res = await this.$api.getBill({
        custname: this.$route.query.custname,
        pkCorp: this.$route.query.pkCorp,
        dprocessdate: this.date1,
        type: this.dateRange,
        p: this.pageNum,
      });
      if (res.code == 1) {
        this.pageSize = res.data.pageSize;
        this.count = res.data.count;
        this.list = res.data.list;
      } else {
        this.$message.error(res.msg);
      }
    },
    async excel() {
      let res = await this.$api.excel({
        custname: this.$route.query.custname,
        pkCorp: this.$route.query.pkCorp,
        dprocessdate: this.date1,
        type: this.dateRange,
      });
      if (res.code == 1) {
        window.open(res.data)
      } else {
        this.$message.error(res.msg);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.detail-container {
  min-height: $main-height;
  padding-top: 46px;

  .crumbs {
    width: 1200px;
    margin: 0 auto;

    .location {
      display: inline-block;
      vertical-align: middle;
      width: 20px;
    }

    .text1 {
      vertical-align: middle;
      font-size: 16px;
      color: #999999;
    }

    .text2 {
      vertical-align: middle;
      font-size: 16px;
      color: $color-primary;
    }
  }

  td {
    line-height: 24px;
    /*padding: 40px!important;*/
  }

  .fl {
    float: left;
    margin-right: 5px;
  }

  .form {
    width: 1200px;
    margin: 20px auto 26px;

    .date-range {
      float: left;

      .date-input /*其实日期*/
      {
        float: left;
        vertical-align: middle;

        span {
          font-size: 14px;
          color: #333333;
          margin-right: 5px;
        }

        .date-box {
          display: inline-block;
          vertical-align: middle;
          width: 251px;
          height: 40px;
          background: #FFFFFF;
          border: 1px solid #DADADA;
          padding: 0 10px;

          ::v-deep.el-date-editor.el-input, .el-date-editor.el-input__inner {
            width: 200px;
          }

          ::v-deep.el-input__inner {
            border: none;
            height: 38px;
          }

          ::v-deep.el-input__prefix, .el-input__suffix {
            display: none;
          }

          > img {
            float: right;
            width: 20px;
            margin-top: 10px;
          }
        }
      }

      //时间段选择
      .date-group {
        float: left;
        overflow: hidden;
        margin-left: 30px;

        .item {
          cursor: pointer;
          float: left;
          width: 89px;
          height: 40px;
          background: #FFFFFF;
          border: 1px solid #999999;
          margin-right: 10px;
          text-align: center;
          line-height: 40px;

          font-size: 14px;
          color: #999999;
        }

        > .active {
          background-color: $color-primary;
          font-size: 14px;
          color: #FFFFFF;
          border: none;
        }
      }

      .search {
        cursor: pointer;
        float: left;
        width: 89px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-left: 40px;

        background-color: #D70D18;
        font-size: 14px;
        color: #FFFFFF;
        border-radius: 20px;

      }

    }

    .output {
      float: right;
      cursor: pointer;

      img {
        vertical-align: middle;
        width: 34px;
        height: 34px;
        margin-right: 7px;
      }

      span {
        vertical-align: middle;
        font-size: 14px;
        color: #333333;
      }
    }
  }

  .table-area {
    width: 1200px;
    margin: 22px auto 86px;

    table {
      width: 100%;

      tr {
        height: 60px;

        td {
          vertical-align: middle;
          font-size: 16px;
          text-align: left;
          &:first-child{padding-left: 40px;}
        }
      }

      thead {
        tr {
          background-color: $color-primary;

          td {
            color: #FFFFFF;
          }

        }
      }

      tbody {
        tr {
          &:nth-child(2n) {
            background-color: #F9F9F9;
          }

          td {
            &:nth-child(4) {
              color: $color-primary
            }

          }
        }
      }
    }
  }

  ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #D70D18;
  }
}
</style>